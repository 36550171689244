import React from "react";

const PostPagination = ({ page, pages }) => {
  const pageArr = [];
  for (let i = 1; i <= pages; i++) {
    pageArr.push(i);
  }

  return (
    <>
      {pages > 1 && (
        <div className="post-pagination">
          {page > 1 && (
            <a href={`/blog/${page - 1}`}>
              <i className="far fa-angle-left"></i>
            </a>
          )}
          {pageArr.map((pageNo) => (
            <a
              key={pageNo}
              href={`/blog/${pageNo}`}
              className={pageNo === page ? "active" : ""}
            >
              {pageNo}
            </a>
          ))}
          {page < pages && (
            <a href={`/blog/${page + 1}`}>
              <i className="far fa-angle-right"></i>
            </a>
          )}
        </div>
      )}
    </>
  );
};

export default PostPagination;
