import React from "react";

const PageHeader = (props) => {
  return (
    <div>
      <section className="page-header">
        <div className="container">
          <h2>{props.pageHeading}</h2>
          <ul className="list-unstyled thm-breadcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            {props.breadcrumb && (
              <>
                {props.breadcrumb.map((item, index) => (
                  <React.Fragment key={index}>
                    {index === props.breadcrumb.length - 1 && (
                      <li>
                        <span>{item.title}</span>
                      </li>
                    )}
                    {index !== props.breadcrumb.length - 1 && (
                      <li>
                        <a href={item.url}>{item.title}</a>
                      </li>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </ul>
        </div>
      </section>
    </div>
  );
};

export default PageHeader;
