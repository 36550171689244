import React from "react";
import { Link } from "gatsby";
import { Col } from "react-bootstrap";
import parse from "html-react-parser";
import slugify from "slugify";
// require("dotenv").config({
//   path: `.env.${process.env.NODE_ENV}`,
// });

const BlogPost = ({ data }) => {
  return (
    <Col lg={4} md={6} sm={12}>
      <div className="blog-one__single">
        {data.Image && (
          <div className="blog-one__image">
            <img
              src={`${process.env.GATSBY_API_URL}${
                data.Image?.formats?.medium?.url ?? data.Image?.url
              }`}
              alt={data.Title}
            />
          </div>
        )}
        <div className="blog-one__content">
          <div className="blog-one__circle"></div>
          <div className="blog-one__content-inner">
            <div className="blog-one__meta">
              <a href="#none">
                <i className="far fa-clock"></i> {data.Date}
              </a>
              {/* <a href="#none">
                <i className="far fa-comments"></i> {data.postCommentCount}
              </a> */}
            </div>
            <h3>
              <Link
                to={`/blog-post/${slugify(data.Title, {
                  lower: true,
                  remove: /[*+~.,\[\](){}'"!?:@]/g,
                })}-${data.strapi_id}`}
              >
                {data.Title}
              </Link>
            </h3>
            <div className="blog-one__content-inner-content">
              {parse(data.Lead.data.Lead)}
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default BlogPost;
